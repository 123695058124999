<template>
  <div>
    <div class="m_banner">
      <div class="m_banner_text">组织结构治理与定岗定编规划</div>
      <div class="m_banner_i"></div>
      <div class="m_banner_text2">科学构建人力资源战略规划，搭建医疗人才梯队</div>
    </div>
    <div class="government_m_box">
      <div>
        <div class="index_title">
          <span class="index_title_before"></span>
          <span class="index_title_span">{{ projectData.title }}</span>
          <span class="index_title_after"></span>
        </div>
        <div class="modern_m_kernel_list">
          <div class="modern_m_kernel_item" v-for="(item, index) in projectData.list" :key="index">
            <img class="modern_m_kernel_img" :src="item.img" alt="">
            <div class="modern_m_kernel_name">{{ item.name }}</div>
            <div class="modern_m_kernel_introduce">{{ item.introduce }}</div>
          </div>
        </div>
      </div>
      <div>
        <div class="index_title">
          <span class="index_title_before"></span>
          <span class="index_title_span">定编方法</span>
          <span class="index_title_after"></span>
        </div>
        <img class="government_finalization_img" src="../assets/img/government_01.png" alt="">
        <div class="government_finalization_list">
          <div class="government_finalization_item">
            <img class="government_finalization_item_img" src="../assets/img/government_08.png" alt="">
            <div class="government_finalization_item_name">排班定编法</div>
          </div>
          <div class="government_finalization_item">
            <img class="government_finalization_item_img" src="../assets/img/government_09.png" alt="">
            <div class="government_finalization_item_name">业务分工定编法</div>
          </div>
          <div class="government_finalization_item">
            <img class="government_finalization_item_img" src="../assets/img/government_10.png" alt="">
            <div class="government_finalization_item_name">工作量定编法</div>
          </div>
          <div class="government_finalization_item">
            <img class="government_finalization_item_img" src="../assets/img/government_05.png" alt="">
            <div class="government_finalization_item_name">比例定编法</div>
          </div>
          <div class="government_finalization_item">
            <img class="government_finalization_item_img" src="../assets/img/government_06.png" alt="">
            <div class="government_finalization_item_name">强制标准法</div>
          </div>
          <div class="government_finalization_item">
            <img class="government_finalization_item_img" src="../assets/img/government_07.png" alt="">
            <div class="government_finalization_item_name">基本分析法</div>
          </div>
        </div>
      </div>
      <div>
        <div class="index_title">
          <span class="index_title_before"></span>
          <span class="index_title_span">项目成果</span>
          <span class="index_title_after"></span>
        </div>
        <div class="achievement_m_box">
          <swiper :options="swiperOption">
            <swiper-slide v-for="(item, index) in list" :key="index">
              <div class="achievement_swiper_item">
                <img class="achievement_swiper_img" :src="item.img" alt="">
                <div class="achievement_swiper_name">{{ item.name }}</div>
                <div class="achievement_swiper_p">
                  <div class="achievement_swiper_introduce" v-for="(i, k) in item.introduce" :key="k">
                    {{ i }}
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div>
        <div class="index_title">
          <span class="index_title_before"></span>
          <span class="index_title_span">实施效果</span>
          <span class="index_title_after"></span>
        </div>
        <div class="implementation_box">
          <swiper :options="swiperOption2">
            <swiper-slide v-for="(item, index) in list2" :key="index">
              <div class="implementation_swiper_item">
                <img class="implementation_swiper_img" :src="item.img" alt="">
                <div class="implementation_swiper_name">{{ item.name }}</div>
                <div class="implementation_swiper_introduce">{{ item.introduce }}</div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination2"></div>
        </div>
      </div>
      <div>
        <div class="index_title">
          <span class="index_title_before"></span>
          <span class="index_title_span">项目完成周期</span>
          <span class="index_title_after"></span>
        </div>
        <div class="index_subhead">项目周期为2个月，分为岗位定岗，人员定编两个工作模块，管理诊断，岗位确认，定编确定和项目成果验收四个工作阶段进行。</div>
        <div>
          <div class="cycle_m_list">
            <div class="cycle_m_item" v-for="(item, index) in list3" :key=index>
              <div class="cycle_m_item_name">{{ item.name }}</div>
              <div class="cycle_m_item_introduce">
                <div class="cycle_m_item_introduce_i" v-for="(i, k) in item.introduce" :key="k">
                  {{ i }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="serve_m_box">
        <div class="index_title">
          <span class="index_title_before"></span>
          <span class="index_title_span">服务案例</span>
          <span class="index_title_after"></span>
        </div>
        <div class="index_subhead">易策医管集团已累计为超过<span style="color: #2D43F2;font-weight: 500">800+</span>家医院提供优质服务</div>
        <div class="serve_m_type">
          <div
            :class="['serve_m_type_item', isType == item.type ? 'serve_m_type_isItem' : '']"
            v-for="(item, index) in hisTypeList"
            :key="index" 
            @click="changeTab(item.type)"
          >
            {{ item.value }}
          </div>
        </div>
        <div class="serve_m_hospital">
          <div class="serve_m_hospital_item" v-for="(item, index) in hisList2" :key="index" @click="getDetails(item.hospitalId)">
            <img class="serve_m_hospital_img" :src="item.hospitalLogo" />
            <div class="serve_m_hospital_name">{{ item.hospitalName }}</div>
          </div>
        </div>
        <div class="index_more" @click="stretch">{{ hisShow ? '收起' : '查看更多' }}</div>
      </div>
  </div>
</template>

<script>
  import { getHospitalList } from "@/api/index";
  export default {
    props: ['projectData'],
    data() {
      return {
        swiperOption: {
          //可见图片张数
          slidesPerView: 1,
          // 默认选中中间一张
          centeredSlides: true,
          // spaceBetween: 30,
          //无限循环
          loop: true,
          //小圆点（我此处没使用，列出供参考）
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            clickableClass : 'my-pagination-clickable',
          },
          //自动轮播
          autoplay: true,
        },
        swiperOption2: {
          //可见图片张数
          slidesPerView: 1,
          // 默认选中中间一张
          centeredSlides: true,
          // spaceBetween: 30,
          //无限循环
          loop: true,
          //小圆点（我此处没使用，列出供参考）
          pagination: {
            el: '.swiper-pagination2',
            clickable: true,
            clickableClass : 'my-pagination-clickable',
          },
          //自动轮播
          autoplay: true,
        },
        list: [
          {
            img: require("../assets/img/government_14.png"),
            name: "定岗",
            introduce: ['编写岗位说明书', '梳理部门工作内容', '明确医院组织架构', '确定岗位'],
          },
          {
            img: require("../assets/img/government_15.png"),
            name: "定编",
            introduce: ['核定医院人员定编', '确定各科室人员编制数量', '拟定医院定编量表'],
          },
          {
            img: require("../assets/img/government_16.png"),
            name: "岗位价值评价",
            introduce: ['组织评价活动', '明确岗位价值评价标准', '评价结果汇总分析报告'],
          }
        ],
        list2: [
          {
            img: require("../assets/img/government_02.png"),
            name: "人力资源管理",
            introduce: '夯实医院高质量发展所需的人力资源管理平台。',
          },
          {
            img: require("../assets/img/government_03.png"),
            name: "建立架构",
            introduce: '建立合理的组织架构体系，确定各工作单元的动态人员定额。',
          },
          {
            img: require("../assets/img/government_04.png"),
            name: "专业化管理",
            introduce: '提高医院人才的专业化管理，完善医院引才育才的制度，减少人员流失。',
          }
        ],
        list3: [
          {
            name: "管理争端",
            introduce: ['医院组织结构，岗位结构情况现状评估', '调查医院人员结构现状', '收集医院近三年的运营数据', '收集医院近三年的运营数据', '收集医院近三年的运营数据'],
          },
          {
            name: "定岗",
            introduce: ['确定医院组织结构，形成组织结构图', '进行部门工作范围的界定，形成部门职责说明书', '进行岗位设计，确定岗位构成，形成岗位说明书'],
          },
          {
            name: "定编",
            introduce: ['医院运营状况分析', '确定医院人员定编标准', '进行医院人员定编方案设计', '进行医院定编测算，比较和分析'],
          },
          {
            name: "项目验收",
            introduce: ['医院组织管理手册验收', '医院岗位管理手册验收', '医院定编方案验收'],
          }
        ],
        isType: null,
        hisShow: false,
        hisTypeList: [
          { type: null, value: "全部" },
          { type: "综合医院", value: "综合医院" },
          { type: "中医医院", value: "中医医院" },
          { type: "妇幼保健院", value: "妇幼保健院" },
          { type: "其他类型", value: "其他类型" },
        ],
        hisList2: []
      }
    },
    created() {
      this.changeTab(null)
    },
    methods: {
      changeTab(i) {
        this.isType = i;
        let data = {
          // hospitalProvince: this.HisTilter,
          hospitalType: i,
        };
        getHospitalList(data).then((res) => {
          if (res.code == 200) {
            this.hisList2 = res.rows.slice(0, 10);
            this.hisListCopy = JSON.parse(JSON.stringify(res.rows))
            this.hisShow = false
          }
        });
      },
      stretch(){
        this.hisShow = !this.hisShow
        if(this.hisShow == false){
          this.hisList2 = this.hisListCopy.slice(0, 10)
        }else{
          this.hisList2 = this.hisListCopy
        }
      },
      getDetails(hospitalId) {
        this.$router.push({
          path: "/serve",
          query: { activeName:2, hospitalId: hospitalId },
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .m_banner {
    width: 100%;
    height: 515px;
    background-image: url("../assets/img/mobile_bg6.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .m_banner_text {
      font-size: 42px;
      color: #fff;
      text-align: center;
      padding-top: 132px;
    }
    .m_banner_i {
      width: 45px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto 16px;
    }
    .m_banner_text2 {
      font-size: 26px;
      color: #fff;
      text-align: center;
    }
  }
  .index_title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #000;
    margin: 0 auto;
    .index_title_span {
      margin: 0 20px;
    }
    .index_title_after {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
    .index_title_before {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .index_subhead {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: #333;
    margin: 23px auto;
  }
  .government_m_box {
    padding: 36px;
    .modern_m_kernel_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 45px 0;
      .modern_m_kernel_item {
        width: 328px;
        text-align: center;
        background: #FFFFFF;
        box-shadow: 0px 3px 10px 1px rgba(146,146,146,0.16);
        border: 1px solid #E1E1E1;
        padding: 29px 0;
        margin-bottom: 30px;
        .modern_m_kernel_img {
          width: 62px;
        }
        .modern_m_kernel_name {
          font-size: 24px;
          font-weight: bold;
          color: #333333;
          padding: 15px 0;
        }
        .modern_m_kernel_introduce {
          font-size: 20px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    .government_finalization_img {
      width: 452px;
      margin: 60px auto;
      display: flex;
    }
    .government_finalization_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .government_finalization_item {
        width: 33.33%;
        text-align: center;
        margin-bottom: 50px;
        .government_finalization_item_img {
          width: 72px;
        }
        .government_finalization_item_name {
          font-size: 24px;
          font-weight: 400;
          color: #333333;
          margin-top: 40px;
        }
      }
    }
    .achievement_m_box {
      padding: 55px 0;
      .achievement_swiper_item {
        height: 543px;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px 1px rgba(185,185,185,0.16);
        border-radius: 10px;
        opacity: 1;
        border: 1px solid #E1E1E1;
        text-align: center;
        .achievement_swiper_img {
          width: 110px;
          margin-top: 65px;
        }
        .achievement_swiper_name {
          font-size: 36px;
          font-weight: bold;
          color: #333333;
          margin: 32px 0;
        }
        .achievement_swiper_p {
          .achievement_swiper_introduce {
            font-size: 26px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 16px;
            .achievement_swiper_i {
              font-size: 26px;
            }
          }
        }
      }
      .swiper-pagination {
        position: inherit;
        width: 100%;
        padding-top: 30px;
        ::v-deep(.swiper-pagination-bullet) {
          width: 22px !important;
          height: 22px !important;
          margin: 0 14px;
        }
        ::v-deep(.swiper-pagination-bullet-active) {
          background-color: #2D43F2 !important;
        }
      }
    }
    .implementation_box {
      padding-bottom: 100px;
      .swiper-pagination2 {
        position: inherit;
        width: 100%;
        text-align: center;
        ::v-deep(.swiper-pagination-bullet) {
          width: 22px !important;
          height: 22px !important;
          margin: 0 14px;
        }
        ::v-deep(.swiper-pagination-bullet-active) {
          background-color: #2D43F2 !important;
        }
      }
    }
    .implementation_swiper_item {
      text-align: center;
      padding: 50px 0;
      .implementation_swiper_img {
        width: 235px;
      }
      .implementation_swiper_name {
        font-size: 26px;
        font-weight: bold;
        color: #333333;
        margin: 32px 0;
      }
      .implementation_swiper_introduce {
        width: 416px;
        margin: 0 auto;
        font-size: 26px;
        font-weight: 400;
        color: #333333;
        line-height: 44px;
      }
    }
    .cycle_m_list {
      .cycle_m_item {
        position: relative;
        margin-top: 100px;
        .cycle_m_item_name {
          width: 343px;
          height: 62px;
          line-height: 62px;
          text-align: center;
          font-size: 26px;
          font-weight: 600;
          color: #FFFFFF;
          position: absolute;
          top: -31px;
          left: calc(50% - 171px);
          z-index: 2;
        }
        .cycle_m_item_introduce {
          background: #FFFFFF;
          box-shadow: 0px 3px 16px 1px rgba(167,167,167,0.2);
          border: 1px solid #E1E1E1;
          padding: 63px 30px;
          .cycle_m_item_introduce_i {
            font-size: 26px;
            font-weight: 400;
            color: #333333;
            line-height: 28px;
            margin-bottom: 23px;
            padding-left: 73px;
            position: relative;
            &::before {
              content: '';
              display: inline-block;
              width: 15px;
              height: 15px;
              background-image: url("../assets/img/cycle_icon.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              position: absolute;
              top: 5px;
              left: 30px;
            }
          }
        }
        &:nth-child(1) {
          .cycle_m_item_name {
            background: #C43694;
          }
        }
        &:nth-child(2) {
          .cycle_m_item_name {
            background: #684AB7;
          }
        }
        &:nth-child(3) {
          .cycle_m_item_name {
            background: #2C4FDB;
          }
        }
        &:nth-child(4) {
          .cycle_m_item_name {
            background: #3370FF;
          }
        }
      }
    }
  }
  .serve_m_box {
      background-color: #fff;
      padding: 50px 37px;
      .serve_m_type {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 0 60px;
        .serve_m_type_item {
          width: fit-content;
          padding: 21px 30px;
          background: #FFFFFF;
          border-radius: 10px;
          border: 1px solid #BFBFBF;
          font-size: 24px;
          font-weight: 400;
          color: #333333;
          margin-bottom: 20px;
        }
        .serve_m_type_isItem {
          color: #fff;
          border: 1px solid #2D43F2;
          background: #2D43F2;
        }
      }
    }
    .serve_m_hospital {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 41px;
      .serve_m_hospital_item {
        width: 48%;
        text-align: center;
        background: #FFFFFF;
        box-shadow: 0px 3px 16px 1px rgba(0,14,52,0.06);
        border-radius: 4px;
        border: 2px solid #E6E6E6;
        margin-bottom: 28px;
        padding: 21px;
        .serve_m_hospital_img {
          width: 138px;
          height: 138px;
        }
        .serve_m_hospital_name {
          font-size: 24px;
          font-weight: 400;
          color: #333333;
          padding-top: 20px;
        }
      }
    }
</style>
