<template>
  <div>
    <div class="pc_government">
      <div class="banner-box">
        <div class="banner-boxs">
          <div class="banner-name">组织结构治理与定岗定编规划</div>
          <div class="banner-name2">
            科学构建人力资源战略规划，搭建医疗人才梯队
          </div>
          <div class="banner-bnt" @click="openPDF">下载资料</div>
        </div>
      </div>
      <div class="government-bg">
        <projectMod :projectData="projectData" />
        <div class="content-box" v-scroll-reveal>
          <div class="plan-title">
            <div class="flex title2">
              <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
              <span>定编方法</span>
              <img
                class="titleIcon1 rotate180"
                src="../assets/img/titleIcon1.png"
                alt=""
              />
            </div>
            <div class="flex mes2">
              <img src="../assets/img/titleIcon2.png" alt="" />
            </div>
          </div>
          <div class="government-finalization">
            <img
              class="government-finalization-left"
              src="../assets/img/government_01.png"
              alt=""
            />
            <div class="government-finalization-right">
              <div class="government-finalization-item">
                <img
                  class="government-finalization-img"
                  src="../assets/img/government_08.png"
                  alt=""
                />
                <div class="government-finalization-name">排班定编法</div>
              </div>
              <div class="government-finalization-item">
                <img
                  class="government-finalization-img"
                  src="../assets/img/government_09.png"
                  alt=""
                />
                <div class="government-finalization-name">业务分工定编法</div>
              </div>
              <div class="government-finalization-item">
                <img
                  class="government-finalization-img"
                  src="../assets/img/government_10.png"
                  alt=""
                />
                <div class="government-finalization-name">工作量定编法</div>
              </div>
              <div class="government-finalization-item">
                <img
                  class="government-finalization-img"
                  src="../assets/img/government_05.png"
                  alt=""
                />
                <div class="government-finalization-name">比例定编法</div>
              </div>
              <div class="government-finalization-item">
                <img
                  class="government-finalization-img"
                  src="../assets/img/government_06.png"
                  alt=""
                />
                <div class="government-finalization-name">强制标准法</div>
              </div>
              <div class="government-finalization-item">
                <img
                  class="government-finalization-img"
                  src="../assets/img/government_07.png"
                  alt=""
                />
                <div class="government-finalization-name">基本分析法</div>
              </div>
            </div>
          </div>
          <div class="government-bnt" @click="openFun(true)">了解详情</div>
        </div>
      </div>
      <div class="government-effect-bg" v-scroll-reveal>
        <div class="content-box">
          <div>
            <div class="flex title2">
              <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
              <span>项目成果</span>
              <img
                class="titleIcon1 rotate180"
                src="../assets/img/titleIcon1.png"
                alt=""
              />
            </div>
            <div class="flex mes2">
              <img src="../assets/img/titleIcon2.png" alt="" />
            </div>
          </div>
          <div class="government-effect">
            <div class="government-effect-item">
              <img
                class="government-effect-img"
                src="../assets/img/government_14.png"
                alt=""
              />
              <div class="government-effect-name">定岗</div>
              <div class="government-effect-introduce">
                <p>
                  <span></span>
                  编写岗位说明书
                </p>
                <p>
                  <span></span>
                  梳理部门工作内容
                </p>
                <p>
                  <span></span>
                  明确医院组织架构
                </p>
                <p>
                  <span></span>
                  确定岗位
                </p>
              </div>
            </div>
            <div class="government-effect-item">
              <img
                class="government-effect-img"
                src="../assets/img/government_15.png"
                alt=""
              />
              <div class="government-effect-name">定编</div>
              <div class="government-effect-introduce">
                <p>
                  <span></span>
                  核定医院人员定编
                </p>
                <p>
                  <span></span>
                  确定各科室人员编制数量
                </p>
                <p>
                  <span></span>
                  拟定医院定编量表
                </p>
              </div>
            </div>
            <div class="government-effect-item">
              <img
                class="government-effect-img"
                src="../assets/img/government_16.png"
                alt=""
              />
              <div class="government-effect-name">岗位价值评价</div>
              <div class="government-effect-introduce">
                <p>
                  <span></span>
                  组织评价活动
                </p>
                <p>
                  <span></span>
                  明确岗位价值评价标准
                </p>
                <p>
                  <span></span>
                  评价结果汇总分析报告
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="government-implementation-bg" v-scroll-reveal>
        <div class="content-box">
          <div style="padding-top: 95px">
            <div class="flex title2">
              <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
              <span>实施效果</span>
              <img
                class="titleIcon1 rotate180"
                src="../assets/img/titleIcon1.png"
                alt=""
              />
            </div>
            <div class="flex mes2">
              <img src="../assets/img/titleIcon2.png" alt="" />
            </div>
          </div>
          <div class="government-implementation">
            <div class="government-implementation-item">
              <img
                class="government-implementation-img"
                src="../assets/img/government_02.png"
                alt=""
              />
              <div class="government-implementation-name">人力资源管理</div>
              <div class="government-implementation-introduce">
                夯实医院高质量发展所需的人力资源管理平台。
              </div>
            </div>
            <div class="government-implementation-item">
              <img
                class="government-implementation-img"
                src="../assets/img/government_03.png"
                alt=""
              />
              <div class="government-implementation-name">建立架构</div>
              <div class="government-implementation-introduce">
                建立合理的组织架构体系，确定各工作单元的动态人员定额。
              </div>
            </div>
            <div class="government-implementation-item">
              <img
                class="government-implementation-img"
                src="../assets/img/government_04.png"
                alt=""
              />
              <div class="government-implementation-name">专业化管理</div>
              <div class="government-implementation-introduce">
                提高医院人才的专业化管理，完善医院引才育才的制度，减少人员流失。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="government-cycle-bg" v-scroll-reveal>
        <div class="content-box">
          <div style="padding-top: 79px">
            <div class="flex title2">
              <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
              <span>项目完成周期</span>
              <img
                class="titleIcon1 rotate180"
                src="../assets/img/titleIcon1.png"
                alt=""
              />
            </div>
            <div class="flex mes2">
              <img src="../assets/img/titleIcon2.png" alt="" />
            </div>
          </div>
          <div class="plan-introduce" style="text-align: center">
            项目周期为2个月，分为岗位定岗，人员定编两个工作模块，管理诊断，岗位确认，定编确定和项目成果验收四个工作阶段进行。
          </div>
          <div class="government-cycle">
            <div class="government-cycle-item">
              <div
                class="government-cycle-name"
                style="background-color: #c43694"
              >
                管理争端
              </div>
              <div class="government-cycle-introduce">
                <p>医院组织结构，岗位结构情况现状评估</p>
                <p>调查医院人员结构现状</p>
                <p>收集医院近三年的运营数据</p>
                <p>组件项目小组</p>
                <p>召开项目启运会</p>
              </div>
            </div>
            <img
              class="government-cycle-img"
              src="../assets/img/government_12.png"
              alt=""
            />
            <div class="government-cycle-item">
              <div
                class="government-cycle-name"
                style="background-color: #684ab7"
              >
                定岗
              </div>
              <div class="government-cycle-introduce">
                <p>确定医院组织结构，形成组织结构图</p>
                <p>进行部门工作范围的界定，形成部门职责说明书</p>
                <p>进行岗位设计，确定岗位构成，形成岗位说明书</p>
              </div>
            </div>
            <img
              class="government-cycle-img"
              src="../assets/img/government_17.png"
              alt=""
            />
            <div class="government-cycle-item">
              <div
                class="government-cycle-name"
                style="background-color: #2c4fdb"
              >
                定编
              </div>
              <div class="government-cycle-introduce">
                <p>医院运营状况分析</p>
                <p>确定医院人员定编标准</p>
                <p>进行医院人员定编方案设计</p>
                <p>进行医院定编测算，比较和分析</p>
              </div>
            </div>
            <img
              class="government-cycle-img"
              src="../assets/img/government_13.png"
              alt=""
            />
            <div class="government-cycle-item">
              <div
                class="government-cycle-name"
                style="background-color: #3370ff"
              >
                项目验收
              </div>
              <div class="government-cycle-introduce">
                <p>医院组织管理手册验收</p>
                <p>医院岗位管理手册验收</p>
                <p>医院定编方案验收</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <serviceAcc :bg="1" />
      <expertItemMod />
      <relation :open="open" @openFun="openFun"/>
    </div>
    <mobileGovernment
      :projectData="projectData"
      class="mobile_government"
    />
  </div>
  
</template>

<script>
import projectMod from "../components/projectMod";
import expertItemMod from "../components/expertItemMod";
import serviceAcc from "../components/serviceAcc";
import relation from "../components/relation";
import mobileGovernment from '@/mobileViews/government.vue'
export default {
  components: {
    projectMod,
    expertItemMod,
    serviceAcc,
    relation,
    mobileGovernment
  },
  data() {
    return {
      open: false,
      projectData: {
        title: "项目内容",
        introduce: "",
        isBnt: false,
        fontWeight: "bold",
        list: [
          {
            img: require("../assets/img/modernImg1.png"),
            name: "01",
            introduce: "医院人力资源体系评估",
          },
          {
            img: require("../assets/img/modernImg2.png"),
            name: "02",
            introduce: "科学设计医院组织架构",
          },
          {
            img: require("../assets/img/modernImg3.png"),
            name: "03",
            introduce: "确定岗位结构规范岗位要求",
          },
          {
            img: require("../assets/img/modernImg4.png"),
            name: "04",
            introduce: "编制岗位说明书",
          },
          {
            img: require("../assets/img/modernImg5.png"),
            name: "05",
            introduce: "制定医院定编标准和定编方案",
          },
          {
            img: require("../assets/img/modernImg6.png"),
            name: "06",
            introduce: "医院定岗定编培训",
          },
        ],
      },
    };
  },
  methods: {
    /** 下载资料 */
    openPDF() {
      window.open(
        "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/other/protalDown/%E6%98%93%E7%AD%96%E5%8C%BB%E7%AE%A1-%E7%BB%84%E7%BB%87%E7%BB%93%E6%9E%84%E6%B2%BB%E7%90%86%E4%B8%8E%E5%AE%9A%E5%B2%97%E5%AE%9A%E7%BC%96%E8%A7%84%E5%88%92.pdf"
      );
    },
    openFun(bo){
      this.open = bo
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-box {
  width: 100%;
  height: 400px;
  background-image: url("../assets/img/government.png");
  background-repeat: no-repeat;
  // background-position: 0 -181px;
  background-size: 100%;
  .banner-boxs {
    width: 1200px;
    margin: 0 auto;

    .banner-name {
      font-size: 52px;
      font-weight: 400;
      color: #fff;
      padding-top: 89px;
    }
    .banner-name2 {
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 31px;
    }

    .banner-bnt {
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #2d43f2;
      border-radius: 50px;
      color: #fff;
      font-size: 16px;
      margin-top: 48px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background: #4273ff;
      }
    }
  }
}

.government-bnt {
  width: 185px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #2d43f2;
  border-radius: 50px;
  color: #fff;
  margin: 86px auto 0;
  cursor: pointer;
}
.plan-title {
  margin-top: 76px;
}
.plan-introduce {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  margin-bottom: 30px;
}
.content-box {
  width: 1200px;
  margin: 0 auto;
}
.government-bg {
  background-image: url("../assets/img/plan_project_bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 164px;
}
.government-finalization {
  display: flex;
  justify-content: space-between;
  .government-finalization-left {
    width: 584px;
    height: 435px;
  }
  .government-finalization-right {
    margin-left: 120px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .government-finalization-item {
      width: 165px;
      text-align: center;
      .government-finalization-img {
        width: 53px;
      }
      .government-finalization-name {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        margin-top: 30px;
      }
    }
  }
}
.government-effect-bg {
  background-image: url("../assets/img/government_11.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 143px;
  padding-top: 109px;
}
.government-effect {
  display: flex;
  justify-content: space-between;
  .government-effect-item {
    text-align: center;
    width: 370px;
    background-color: #fff;
    padding: 59px 15px 38px;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 3px 6px 1px rgba(185, 185, 185, 0.16);
    transition: all 0.5s;
    &:hover {
      box-shadow: 0px 3px 16px 1px rgba(99, 99, 99, 0.16);
    }
    .government-effect-img {
      width: 64px;
      height: 64px;
    }
    .government-effect-name {
      font-size: 22px;
      font-weight: 400;
      color: #333333;
      margin-top: 18px;
      margin-bottom: 10px;
    }
    .government-effect-introduce {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      p {
        margin: 5px auto;
        span {
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: #177ffc;
          border-radius: 100%;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  }
}
.government-implementation-bg {
  background-image: url("../assets/img/core_advantage_bg2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: left bottom;
  padding-bottom: 93px;
}
.government-implementation {
  display: flex;
  justify-content: space-between;
  .government-implementation-item {
    width: 400px;
    text-align: center;
    .government-implementation-img {
      width: 180px;
      height: 180px;
    }
    .government-implementation-name {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin: 42px auto 21px;
    }
    .government-implementation-introduce {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      padding: 0 72px;
    }
  }
}
.government-cycle-bg {
  background-image: url("../assets/img/cycle_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 107px;
}
.government-cycle {
  display: flex;
  align-items: center;
  .government-cycle-item {
    width: 275px;
    height: 336px;
    background-color: #fff;
    .government-cycle-name {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      padding: 13px;
      text-align: center;
    }
    .government-cycle-introduce {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 26px;
      p {
        padding: 0 30px 0 51px;
        margin: 15px 0;
        position: relative;
        &::before {
          content: "";
          width: 14px;
          height: 14px;
          position: absolute;
          top: 5px;
          left: 25px;
          background-image: url("../assets/img/review_13.png");
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
    }
  }
  .government-cycle-img {
    width: 11px;
    height: 25px;
    margin: 0 11px;
  }
}
.mobile_government {
  display: none;
}
@media screen and (max-width: 650px) {
  .mobile_government {
    display: block;
    background-color: #fff;
  }
  .pc_government {
    display: none;
  }
}
</style>
