<template>
  <div class="content-box" v-scroll-reveal>
    <div class="plan-title">
      <div class="flex title2">
        <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="">
        <span>{{ projectData.title }}</span>
        <img class="titleIcon1 rotate180" src="../assets/img/titleIcon1.png" alt="">
      </div>
      <div class="flex mes2">
        <img src="../assets/img/titleIcon2.png" alt="">
      </div>
    </div>
    <div class="plan-introduce" style="text-align: center;" v-if="projectData.introduce">
      {{ projectData.introduce }}
    </div>
    <div class="plan-box">
      <div class="plan-item" v-for="(item, index) in projectData.list" :key="index">
        <img class="plan-icon" :src="item.img" alt="">
        <div>
          <div class="plan-name" :style="{ 'fontWeight': projectData.fontWeight }">{{ item.name }}</div>
          <div class="plan-item-introduce">{{ item.introduce }}</div>
        </div>
      </div>
    </div>
    <div class="plan-bnt" v-if="projectData.isBnt" @click="openFun(true)">
      了解详情
    </div>
  </div>
</template>

<script>
export default {
  name: "projectMod",
  props: ['projectData'],
  data() {
    return {
    };
  },
  methods: {
    openFun(bo){
      this.$emit('relationUs',true)
    }
  },
};
</script>

<style lang="scss" scoped>
  .content-box {
    width: 1200px;
    margin: 0 auto;
  }
  .plan-title {
    margin-top: 76px;
  }

  .plan-introduce {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .plan-box {
    display: flex;
    flex-wrap: wrap;
    .plan-item {
      width: 360px;
      margin-right: 57px;
      margin-top: 57px;
      border: 1px solid #E1E1E1;
      background-color: #fff;
      box-shadow: 0px 3px 6px 1px rgba(185,185,185,0.16);
      text-align: center;
      transition: all 0.6s;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(-n+3) {
        margin-top: 0;
      }
      &:hover {
        transform: scale(1.02);
        box-shadow: 0px 3px 10px 1px rgba(146,146,146,0.16);;
      }
      .plan-icon {
        width: 66px;
        // height: 66px;
        display: inline-block;
        margin: 42px auto 0 auto;
      }
      .plan-name {
        font-size: 18px;
        color: #333333;
        margin-top: 17px;
        padding: 0 12px;
      }
      .plan-item-introduce {
        font-size: 16px;
        font-weight: 400;
        color: #707070;
        line-height: 24px;
        margin-top: 14px;
        margin-bottom: 32px;
        padding: 0 12px;
      }
    }
  }

  .plan-bnt {
    width: 185px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background: #2D43F2;
    border-radius: 50px;
    color: #fff;
    margin: 67px auto 0;
    cursor: pointer;
  }
</style>